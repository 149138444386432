<template>
  <div class="top-logo">
    7KEY MUSIC Official
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {}
  },
  components: {},
  computed: {},
  created() {
  },
  mounted() {
  },
  watch: {},
  methods: {}
}
</script>

<style scoped lang='less'>
.top-logo {
  width: 100%;
  height: 120px;
  font-size: 60px;
  line-height: 120px;
  text-align: center;
  color: @buttonTextColor;
  border-radius: 5px;
  font-family: PingFangSC-Medium, PingFang SC;
  background-color: @loginTopLogoBgColor;
  border: solid 1px @loginTopLogoBorderColor !important;
}
</style>
